<script>
	// import CommonApi from '@/api/CommonApi'
	export default {
		name: 'AlarmGrade',
		data() {
			return {
				tableHeight: 200,
				tableLoading: false,
				tableData: []
			}
		},
		mounted() {
			this.getDictList()
			this.tableHeight = this.$refs.tableContainer.clientHeight - 54
			window.addEventListener('resize', this.tableResize)
		},
		destroyed() {
			window.removeEventListener('resize', this.tableResize)
		},
		methods: {
			getDictList() {
				this.tableLoading = true
				this.$api.getDictList('WARN_LEVEL')
					.then((response) => {
						if (response.code == 200) {
							this.tableData = response.data
								.sort((a, b) => a.sort - b.sort)
								.map((item, index) => ({
									key: index + 1,
									name: item.name,
									remarks: item.remarks
								}))
						}
						this.tableLoading = false
					})
					.catch(() => {
						this.tableLoading = false
					})
			},
			tableResize() {
				this.tableHeight = this.$refs.tableContainer.clientHeight - 54
			}
		}
	}
</script>

<template>
	<div class="rate-manage-container">
		<!-- <a-breadcrumb class="container-breadcrumb">
			<a-breadcrumb-item>预警等级</a-breadcrumb-item>
		</a-breadcrumb> -->
		<div class="container-content" ref="tableContainer">
			<a-table
				:locale="{ emptyText: this.tableLoading ? '加载中...' : '暂无数据' }"
				:scroll="{ y: tableHeight }"
				:columns="[
					{
						title: '序号',
						dataIndex: 'key',
						align: 'center',
						width: 90
					},
					{
						title: '预警等级',
						dataIndex: 'name',
						align: 'center',
						ellipsis: true
					},
					{
						title: '等级说明',
						dataIndex: 'remarks',
						align: 'center'
					}
				]"
				:data-source="tableData"
				:pagination="false"
			/>
		</div>
	</div>
</template>
